import { useState, useEffect } from "react";
import { toast } from "sonner";

import { LoadingCircle } from "@shared/ui";

import { useGeneratedLineup } from "@entities/generatedLineup";
import { useGeneral } from "@entities/general";
import { useLineupSettings } from "@entities/lineupSettings";

import { LoadSavedLineup } from "@features/loadSavedLineup";

import { fetchSavedLineup, fetchSavedLineups } from "../../api";
import { ClearSavedLineups } from "@features/clearSavedLinups";
import { fetchContestParticipants } from "@features/chooseContest/api";
import { parseProjectionPoints } from "@shared/lib";

export const SavedLineups = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [savedLineups, setSavedLineups, showSideMenu, setShowSideMenu, setRequestLoading] = useGeneral((state) => [
    state.savedLineups,
    state.setSavedLineups,
    state.showSideMenu,
    state.setShowSideMenu,
    state.setRequestLoading,
  ]);

  const [setSelectedContest, setContestPlatform, setContestLeague, setLineupAmount, setContestParticipants] =
    useLineupSettings((state) => [
      state.setSelectedContest,
      state.setContestPlatform,
      state.setContestLeague,
      state.setLineupAmount,
      state.setContestParticipants,
    ]);

  const [setLineups, setCurrentLineup] = useGeneratedLineup((state) => [state.setLineups, state.setCurrentLineup]);

  useEffect(() => {
    if (!showSideMenu) return;

    setIsLoading(true);
    fetchSavedLineups()
      .then((response) => {
        setSavedLineups(response);
      })
      .catch((error) => {
        console.error("Error in Fetching Saved Lineups:", error);
        toast.error("Failed to fetch saved lineups");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showSideMenu]);

  const updateLineupSettings = async (savedLineup: Api.SavedLineup) => {
    savedLineup.players = savedLineup.players.sort((a, b) => a.slotNumber - b.slotNumber);
    const participants = await fetchContestParticipants(savedLineup.contest.id);
    const projectedPointsSortedParticipants = {
      teams: participants.teams,
      participants: participants.participants.sort((a, b) => {
        return Number(parseProjectionPoints(b.projectedPoints)) - Number(parseProjectionPoints(a.projectedPoints));
      }),
    };
    setSelectedContest(savedLineup.contest);
    setContestParticipants(projectedPointsSortedParticipants);
    setContestPlatform(savedLineup.contest.platform as DfsPlatform);
    setContestLeague(savedLineup.contest.league as SportLeague);
    setLineupAmount(1);
    setLineups([savedLineup]);
    setCurrentLineup(1);
  };

  const onClickSavedLineup = async (savedLineupId: string) => {
    try {
      setRequestLoading({ status: true, message: "Loading saved lineup" });
      const savedLineup = await fetchSavedLineup(savedLineupId);
      await updateLineupSettings(savedLineup);
      setIsLoading(false);
      setShowSideMenu(false);
    } catch (error) {
      console.error("Error in Fetching Saved Lineup:", error);
      toast.error("Failed to fetch saved lineup");
    } finally {
      setRequestLoading({ status: false });
    }
  };

  if (isLoading) {
    return (
      <div className="flex-1 overflow-y-auto flex w-full justify-center pt-5">
        <LoadingCircle size="medium" color="white" />
      </div>
    );
  }

  return (
    <>
      {savedLineups.length === 0 ? (
        <p className="flex-1 overflow-y-auto text-center text-gray-400">No saved lineups</p>
      ) : (
        <div className="flex-1 overflow-y-auto">
          {savedLineups.map((savedLineup) => (
            <LoadSavedLineup
              key={savedLineup.id}
              savedLineup={savedLineup}
              onClickSavedLineup={() => onClickSavedLineup(savedLineup.id)}
            />
          ))}
          <hr className="mt-2 border-gray-600" />
          <ClearSavedLineups />
        </div>
      )}
    </>
  );
};
